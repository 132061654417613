@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply overflow-x-hidden overflow-y-auto bg-white text-primary font-secondary;
  }
  .h1 {
    @apply text-[54px] lg:text-[96px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }
  .section {
    @apply w-screen h-screen;
  }
  .btn {
    @apply py-[18px] px-[50px] h-[66px] flex items-center justify-center text-base uppercase font-secondary font-semibold bg-primary text-white;
  }
  .navbarBg {
    @apply transition-all duration-300 ease-in-out bg-white shadow-md;
  }
}